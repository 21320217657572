/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Title, ColumnCard, Text, Image, ColumnCover, ColumnDiv, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu mt--50 pb--12 pt--12" menu={true} name={"menu"} style={{"left":0,"z-index":"99","position":"absolute","marginBottom":0}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center" style={{"marginBottom":19}}>
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#onas"} style={{"style":5}} target={""} content={"O NÁS"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#vimperk"} style={{"style":5}} target={""} content={"VIMPERK"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#volary"} style={{"style":5}} target={""} content={"VOLARY"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#kontakt"} style={{"style":5}} target={""} content={"KONTAKT"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-dm9hf7 bg--center --full pt--30" name={"uvod"} style={{"border":"1.8em solid var(--color-main-2)","marginBottom":133,"paddingBottom":0,"backgroundColor":"rgba(155, 155, 155, 0.64)"}} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/5124/45d0fb06438f4bf5b40e681e625a33e4_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="--center el--1 flex--bottom" style={{"maxWidth":750,"paddingBottom":101.265625}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":89.15625}}>
              
              <Title className="title-box title-box--center ff--3 fs--72 w--500 ls--12 lh--14" content={"<span style=\"color: rgb(255, 255, 255);\">Taxi <a href=\"#menu\" style=\"\">Vimperk</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="--center el--1 pb--30 flex--bottom" style={{"maxWidth":260,"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" name={"onas"} style={{"paddingTop":53,"backgroundColor":"rgba(155, 155, 155, 0.2)"}} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 w--600 ls--02" style={{"paddingBottom":9.5625}} content={"O nás"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnCard className="column__card">
              
              <ColumnWrapper className="--shadow" style={{"paddingTop":32,"paddingBottom":27.1953125,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
                
                <Text className="text-box text-box--left fs--26" style={{"fontStyle":"italic","paddingTop":0,"paddingBottom":30.7265625}} content={"<span style=\"color: rgb(0, 0, 0);\">Jsme tu pro Vás od 15.2.2015 k dispozici 24 hodin denně .&nbsp;<br><br>Nabízíme tyto služby:<br>- odvoz Vašeho auta z večírků, oslav, maturitních plesů, svateb<br>- poskytujeme službu drobných nákupů s donáškou <br>- možnost převozu objemnějších nákladů<br>- expresní přeprava balíků (i mimo ČR)<br>- připojení káry nebo přívěsu<br>- dovoz pizzy , kebabu atd.<br><br>&nbsp;U nás je Vaše spokojenost na prvním místě, nebudete dlouho čekat na přistavení vozidla (dojezd do 10 minut) a jsme schopni odvést až 8 osob najednou.<br>Máme k dispozici 4 osobní automobily a jednoho Transportera (8 míst k sezení).</span><br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnCard>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"nabizime"} style={{"backgroundColor":"rgba(155, 155, 155, 0.2)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"gridGap":"0.5em"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/img-placeholder--cz--sc--v1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--style2 fs--20 btn-box--invert" href={"#vimperk"} content={"Vimperk"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/img-placeholder--cz--sc--v1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--style2 fs--20 btn-box--invert" href={"#volary"} content={"Volary"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/i/img-placeholder--cz--sc--v1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--style2 fs--20 btn-box--invert" href={"#cenik"} content={"Ceník služeb"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"vimperk"} style={{"backgroundColor":"rgba(155, 155, 155, 0.2)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--600" content={"Vimperk"}>
              </Title>

              <Text className="text-box fs--20" content={"<span style=\"color: rgb(0, 0, 0);\">Od 15.2.2015 poskytujeme přepravní služby ve Vimperku cena je od 60,-Kč město. Mimo město od 20,- Kč/km. <br>U nás si můžete objednat odvoz k lékaři, na nákup, kamkoliv kdykoliv. <br>Tel.: 604 56&nbsp; 60 56.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"volary"} style={{"backgroundColor":"rgba(155, 155, 155, 0.2)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36 w--600" content={"Volary"}>
              </Title>

              <Text className="text-box fs--20" style={{"paddingTop":26,"paddingBottom":31.7109375}} content={"<span style=\"color: rgb(0, 0, 0);\">Od 15.8.2018 poskytujeme přepravní služby ve Volarech. Místní TAXI služba je od 50,- Kč po městě, mimo město 20,-Kč/km.<br>&nbsp;U nás si můžete objednat odvoz k lékaři, na nákup, kamkoliv kdykoliv. Drobný nákup s donáškou do domu je jedna z mnoha služeb z naší nabídky. <br>Tel: 723 663 063&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"cenik"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(155, 155, 155, 0.21)"}}>
          
          <ColumnWrap className="column__flex --center el--2 pb--50 pt--50 flex--center" style={{"border":"1em solid var(--color-main-3)","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box w--600" content={"Vimperk<br><span style=\"font-weight: normal;\">(město)</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"— od 60,- Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box w--600" content={"Volary <br><span style=\"font-weight: normal;\">(město)</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"— 50,- Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box w--600" content={"Vimperk<br><span style=\"font-weight: normal;\">(mimo město)</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"— od 11,- Kč/km"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box w--600" content={"Volary <br><span style=\"font-weight: normal;\">(mimo město)</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"— od 11,- Kč/km"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(155, 155, 155, 0.19)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--20 pb--60 pt--60 flex--center" style={{"border":"1em solid var(--color-main-3)","maxWidth":"","marginTop":null,"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"KONTAKT"}>
              </Title>

              <Text className="text-box fs--36 w--900" style={{"maxWidth":500,"paddingBottom":37.9609375}} content={"Petr Domín"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"+420 604 56 60 56 Vimperk<br>+420 663 063 Volary<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"taxi.vimperk@seznam.cz"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"K Rokli 497, 385 01 Vimperk"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}